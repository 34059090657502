
import { detect } from 'detect-browser'

// Add browser and version as an html class, makes browser sass mixin work
const browser = detect()
if (browser) {
  const $html = document.querySelector('html')
  $html.classList.add(`browser--${browser.name}-${browser.version}`)
}

// Export detected browser
export default browser
