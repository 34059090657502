
import Swiper from 'swiper'

import ListingModule from '../../module/listing/listing.js'
import { getInstance } from '../../../scripts/view.js'

const name = 'calendar'

// Range of slides to keep alive
const visibleSlideCount = 3
const leftSlideCount = 1
const rightSlideCount = visibleSlideCount + 1

// Number of months to fetch events for in one call
const fetchMonths = 6

export default class Calendar {
  /**
   * Constructor
   * @param {HTMLElement} $element Root element
   */
  constructor ($element) {
    this.$element = $element

    // Bind elements
    const $swiper = this.$element.querySelector(`.${name}__swiper`)
    const $nextBtn = $element.querySelector(`.${name}__btn-next`)
    const $previousBtn = $element.querySelector(`.${name}__btn-previous`)
    const $data = $element.querySelector(`.${name}__data`)

    // Parse data
    this.data = JSON.parse($data.innerHTML)

    // Bind listing
    this.listing = null
    if (this.data.listingId) {
      const $listing =
        document.querySelector(`.module-listing[data-id="${this.data.listingId}"]`)
      if ($listing) {
        this.listing = getInstance($listing, ListingModule)
      }
    }

    // Set reference date
    const now = new Date()
    const referenceDate = new Date(
      this.data.year || now.getFullYear(),
      (this.data.month || (now.getMonth() + 1)) - 1
    )
    this.refDate = this.toBeginOfMonth(referenceDate)
    this.monthIndex = 0

    // Calculate initial date range for which events get fetched
    this.fromDate = this.addMonths(this.refDate, -leftSlideCount)
    this.toDate = this.addDays(this.addMonths(this.refDate, rightSlideCount), -1)

    // Loading state
    this.loading = false

    // Object mapping timestamps for each day to an array of events
    this.dateEvents = {}

    // Configure and init swiper instance
    this.swiper = new Swiper($swiper, {
      wrapperClass: `${name}__wrapper`,
      slideClass: `${name}__slide`,
      slidesPerView: 'auto'
    })

    // Listen to navigation button events
    $nextBtn.onclick = evt => {
      evt.preventDefault()
      this.swiper.slideNext(200)
    }
    $previousBtn.onclick = evt => {
      evt.preventDefault()
      this.swiper.slidePrev(200)
    }

    // Listen to slide change events
    this.swiper.on('slideChangeTransitionEnd', this.slideDidChange.bind(this))

    // Initial slide change event call
    this.slideDidChange()

    // Fetch initial events
    this.fetchEvents(this.fromDate, this.toDate)
  }

  /**
   * Triggered when the swiper slide transition ends. Lazily creates new slides
   * on both ends or removes slides that are out of range.
   */
  slideDidChange () {
    // Keep alive 1 previous and 4 upcoming months
    let slideIndex = this.swiper.activeIndex
    let count = this.swiper.slides.length

    // Update active relative month index only on non-initial calls
    if (count > 0) {
      this.monthIndex += slideIndex - leftSlideCount

      if (this.listing) {
        const monthDate = this.addMonths(this.refDate, this.monthIndex)
        const archiveUrl = this.data.archiveUrlTemplate
          .replace('%year%', monthDate.getFullYear())
          .replace('%month%', ('0' + (monthDate.getMonth() + 1)).substr(-2, 2))
        this.listing.loadPage(archiveUrl)
      }
    }

    // Remove slides from the beginning
    while (slideIndex > leftSlideCount) {
      this.swiper.removeSlide(0)
      slideIndex--
      count--
    }

    // Remove slides from the end
    while (count - slideIndex > rightSlideCount) {
      this.swiper.removeSlide(count - 1)
      count--
    }

    // Append new slides
    let newSlides = null
    while (count - slideIndex < rightSlideCount) {
      let monthDate = this.addMonths(this.refDate,
        this.monthIndex - slideIndex + count)
      this.swiper.appendSlide(this.renderMonthSlide(monthDate))
      count++

      if (monthDate > this.toDate) {
        newSlides = 'future'
      }
    }

    // Prepend new slides
    while (slideIndex < leftSlideCount) {
      let monthDate = this.addMonths(this.refDate,
        this.monthIndex + slideIndex - leftSlideCount)
      this.swiper.prependSlide(this.renderMonthSlide(monthDate))
      slideIndex++
      count++

      if (monthDate < this.fromDate) {
        newSlides = 'past'
      }
    }

    // Fetch more events, if necessary
    if (newSlides !== null) {
      this.fetchMoreEvents(newSlides === 'future')
    }
  }

  /**
   * Updates the slide for the given month date.
   * @param {Date} monthDate Month date
   * @return {void}
   */
  updateMonthSlide (monthDate) {
    // Search for the month slide
    let date = new Date(this.refDate.valueOf())
    date.setMonth(date.getMonth() + this.monthIndex - leftSlideCount)

    let $slide = null
    let i = 0
    while ($slide === null && i < leftSlideCount + rightSlideCount) {
      if (date.getFullYear() === monthDate.getFullYear() &&
          date.getMonth() === monthDate.getMonth()) {
        $slide = this.swiper.slides[i]
      } else {
        date = this.addMonths(date, 1)
        i++
      }
    }

    // Slide for this month is not visible
    if ($slide === null) {
      return
    }

    // Update slide
    const $updatedMonth = this.renderMonth(monthDate)
    $slide.replaceChild($updatedMonth, $slide.firstChild)
  }

  /**
   * Renders a month slide.
   * @param {Date} monthDate Month date
   * @return {HTMLElement} Slide element
   */
  renderMonthSlide (monthDate) {
    // Wrap month into a slide and add it to the swiper
    const $slide = document.createElement('div')
    $slide.className = `${name}__slide`
    $slide.appendChild(this.renderMonth(monthDate))
    return $slide
  }

  /**
   * Renders a month slide for the given month date.
   * @param {Date} monthDate Month date
   * @return {HTMLElement} New slide element
   */
  renderMonth (monthDate) {
    const $month = document.createElement('div')
    $month.className = `${name}__month`

    // Month headline
    const monthLabel = this.data.monthLabels[monthDate.getMonth()]
    const headline =
      monthDate.getFullYear() !== new Date().getFullYear()
      ? `${monthLabel} ${monthDate.getFullYear()}`
      : monthLabel
    const $monthHeadline = document.createElement('h3')
    $monthHeadline.className = `${name}__month-headline`
    $monthHeadline.innerText = headline
    $month.appendChild($monthHeadline)

    const $table = document.createElement('table')
    $table.role = 'grid'
    $table.className = `${name}__month-table`
    $month.appendChild($table)

    // Weekdays table row
    const $weekdaysRow = document.createElement('tr')
    $table.appendChild($weekdaysRow)

    // Weekdays
    for (let i = 0; i < 7; i++) {
      const $weekdayColumn = document.createElement('th')
      $weekdayColumn.scope = 'col'
      $weekdayColumn.className = `${name}__weekday`
      $weekdayColumn.innerText = this.data.weekdayLabels[i]
      $weekdaysRow.appendChild($weekdayColumn)
    }

    // Month day rows
    const now = new Date()
    let day = this.toBeginOfMonth(monthDate)

    const monthEndDate = this.addDays(this.addMonths(monthDate, 1), -1)

    // Start with Monday
    day = this.addDays(day, -(day.getDay() === 0 ? 6 : day.getDay() - 1))

    // Go through month table rows
    while (day <= monthEndDate) {
      const $week = document.createElement('tr')
      $table.appendChild($week)

      for (let j = 0; j < 7; j++) {
        const $field = document.createElement('td')
        $week.appendChild($field)

        // Hide other month's days
        if (day.getMonth() === monthDate.getMonth()) {
          const $day = document.createElement('span')
          $day.className = `${name}__day`
          $field.appendChild($day)

          const $date = document.createElement('span')
          $date.className = `${name}__day-date`
          $date.innerText = day.getDate()
          $day.appendChild($date)

          // Apply date modifiers (today, past)
          if (this.isToday(day)) {
            $day.classList.add(`${name}__day--today`)
          } else if (day < now) {
            $day.classList.add(`${name}__day--past`)
          }

          // Render indicators
          const indicators = this.getIndicatorsForDay(day)
          if (indicators.length > 0) {
            const $indicators = document.createElement('div')
            $indicators.className = `${name}__day-indicator`
            $day.appendChild($indicators)

            for (const indicator of indicators) {
              const $indicator = document.createElement('div')
              $indicator.className =
                `${name}__indicator ${name}__indicator--${indicator.name}`
              $indicators.appendChild($indicator)

              $day.classList.add(`${name}__day--${indicator.name}`)
            }

            const $label = document.createElement('span')
            $label.className = `${name}__day-label`
            $label.innerText =
              indicators.map(indicator => indicator.label).join(', ')
            $day.appendChild($label)
          }
        }

        // Move to the next day
        day = this.addDays(day, 1)
      }
    }

    return $month
  }

  /**
   * Retrieves an array of indicators for the given date.
   * @param {Date} date Date
   * @return {string[]} Indicator styles
   */
  getIndicatorsForDay (date) {
    const events = this.dateEvents[date.getTime()] || []
    const indicators = []

    for (let i = 0; i < events.length; i++) {
      const type = events[i].type
      const indicator = this.data.typeIndicators[type]
      if (indicator !== undefined && indicators.indexOf(indicator) === -1) {
        indicators.push(indicator)
      }
    }

    return indicators
  }

  /**
   * Fetch more events in the future or in the past.
   * @param {Boolean} future Wether to retrieve future events
   * @return {void}
   */
  async fetchMoreEvents (future = true) {
    // Calculate date range
    let fromDate, toDate
    if (future) {
      fromDate = this.toDate
      toDate = this.addDays(this.addMonths(this.toDate, fetchMonths + 1), -1)
    } else {
      toDate = this.fromDate
      fromDate = this.addMonths(this.fromDate, -fetchMonths)
    }

    // Fetch events
    const success = await this.fetchEvents(fromDate, toDate)

    // Expand date range events have been fetched for
    if (future) {
      this.toDate = toDate
    } else {
      this.fromDate = fromDate
    }
  }

  /**
   * Fetch events inside the given date range.
   * @param {Date} fromDate Date range from
   * @param {Date} toDate Date range to
   * @return {Promise<boolean>} True, if request succeeded
   */
  async fetchEvents (fromDate, toDate) {
    // Ignore fetch requests while loading
    if (this.loading) {
      return
    }

    // Set loading flag
    this.loading = true

    // Compose events API call
    const fromTime = Math.round(fromDate.getTime() / 1000)
    const toTime = Math.round(toDate.getTime() / 1000)
    const eventsUrl = `/api/aerenzdall/v1/events?from=${fromTime}&to=${toTime}`

    // Request events using XMLHttpRequest (IE11 support)
    let events
    try {
      const response = await new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest()
        xhr.open('GET', eventsUrl)
        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4) {
            if (xhr.status >= 200 && xhr.status < 300) {
              resolve(xhr.response)
            } else {
              reject()
            }
          }
        }
        xhr.send(null)
      })

      // Parse response JSON
      events = JSON.parse(response)
    } catch (error) {
      // Fetching events failed
      this.loading = false
      return false
    }

    // Iterate through events
    let event
    let updateMonthTimestamps = []
    for (let i = 0; i < events.length; i++) {
      event = events[i]

      // Calculate event date
      event.date = new Date()
      event.date.setTime(event.time * 1000)
      let dateTimestamp = this.toBeginOfDay(event.date).getTime()

      // Add event to this date
      if (this.dateEvents[dateTimestamp] === undefined) {
        this.dateEvents[dateTimestamp] = []
      }
      this.dateEvents[dateTimestamp].push(event)

      // Keep track of month timestamps that need to be updated
      const monthTimestamp = this.toBeginOfMonth(event.date).getTime()
      if (updateMonthTimestamps.indexOf(monthTimestamp) === -1) {
        updateMonthTimestamps.push(monthTimestamp)
      }
    }

    // Update months that received events
    for (let i = 0; i < updateMonthTimestamps.length; i++) {
      let monthDate = new Date()
      monthDate.setTime(updateMonthTimestamps[i])
      this.updateMonthSlide(monthDate)
    }

    // Clear loading flag
    this.loading = false
    return true
  }

  /**
   * Check wether the given date is today.
   * @param {Date} date Date to be checked
   * @return {Boolean} True, if today
   */
  isToday (date) {
    const now = new Date()
    return (
      date.getDate() === now.getDate() &&
      date.getMonth() === now.getMonth() &&
      date.getFullYear() === now.getFullYear()
    )
  }

  /**
   * Translates the given date to the beginning of the day.
   * @param {Date} date Date to be translated
   * @return {Date} New date instance
   */
  toBeginOfDay (date) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate())
  }

  /**
   * Translates the given date to the beginning of the month.
   * @param {Date} date Date to be translated
   * @return {Date} New date instance
   */
  toBeginOfMonth (date) {
    return new Date(date.getFullYear(), date.getMonth(), 1)
  }

  /**
   * Adds a number of months to the given date.
   * @param {Date} date Date to be translated
   * @return {Date} New date instance
   */
  addMonths (date, months) {
    const resultDate = new Date(date.valueOf())
    resultDate.setMonth(resultDate.getMonth() + months)
    return resultDate
  }

  /**
   * Adds a number of days to the given date.
   * @param {Date} date Date to be translated
   * @return {Date} New date instance
   */
  addDays (date, days) {
    const resultDate = new Date(date.valueOf())
    resultDate.setDate(resultDate.getDate() + days)
    return resultDate
  }
}
