
import Swiper from 'swiper'

const name = 'teaser-grid'

export default class TeaserGrid {
  /**
   * Constructor
   * @param {HTMLElement} $element
   */
  constructor ($element) {
    this.swiper = null

    // Read element attrs
    this.$element = $element

    // Initialize swiper
    if (this.$element.classList.contains(`${name}--swiper`)) {
      window.addEventListener('resize', this.layoutSwiper.bind(this))
      this.layoutSwiper()
    }
  }

  /**
   * Triggered on resize events. Switches to Swiper view for breakpoints XS & S.
   * @return {void}
   */
  layoutSwiper () {
    const enabled = window.innerWidth < 1000
    if ((this.swiper !== null) !== enabled) {
      if (enabled) {
        const $swiper = this.$element.querySelector(`.${name}__swiper`)
        this.swiper = new Swiper($swiper, {
          wrapperClass: `${name}__items`,
          slideClass: `${name}__item`,
          slidesPerView: 'auto',
          navigation: {
            // TODO: Fix nav button disabled state
            disabledClass: `${name}__swiper-nav-button--disabled`
          }
        })

        const $prevEl = this.$element.querySelector(`.${name}__swiper-nav-prev`)
        $prevEl.addEventListener('click', () => {
          this.swiper.slidePrev()
        })

        const $nextEl = this.$element.querySelector(`.${name}__swiper-nav-next`)
        $nextEl.addEventListener('click', () => {
          this.swiper.slideNext()
        })
      } else {
        this.swiper.destroy()
        this.swiper = null
      }
    }
  }

  update () {
    if (this.swiper !== null) {
      this.swiper.update()
    }
  }
}
