
const name = 'article-accordion'
const speed = 25

export default class ArticleAccordion {

  constructor ($element) {
    this.$element = $element
    this.expanded = false

    this.$handle = $element.querySelector(`.${name}__handle`)
    this.$handle.onclick = this.handleDidToggle.bind(this)

    this.$drawer = $element.querySelector(`.${name}__drawer`)
    this.$content = $element.querySelector(`.${name}__content`)
  }

  handleDidToggle (evt) {
    evt.preventDefault()
    this.setExpanded(!this.expanded)
  }

  setExpanded (expanded = true) {
    if (this.expanded !== expanded) {
      this.expanded = expanded

      // Measure content height
      const height = this.$content.getBoundingClientRect().height

      if (!expanded) {
        this.$drawer.style.height = `${height}px`
        // Trigger browser layout
        this.$drawer.getBoundingClientRect()
      }

      // Configure transition speed depending on content height
      // Transition order defined in CSS: height, opacity, visibility
      const duration = Math.max(Math.sqrt(height) * speed, 300)
      this.$drawer.style.transitionDuration =
        `${duration}ms, 200ms, 200ms`

      if (expanded) {
        this.$drawer.style.transitionDelay =
          `0ms, ${duration - 300}ms, ${duration - 300}ms`
      } else {
        this.$drawer.style.transitionDelay = '0ms'
      }

      // Listen to the transition end event; add a short delay to let the
      // transition finish without cutting off frames at the end
      this.$drawer.ontransitionend = () => {
        setTimeout(this.expandedDidChange.bind(this, expanded), 100)
      }

      if (expanded) {
        // Apply style and modifier to trigger transition
        this.$drawer.style.height = `${height}px`
        this.$element.classList.add(`${name}--expanded`)
        this.$element.setAttribute('aria-expanded', 'true');
      } else {
        // Apply style and remove modifier to trigger translation
        this.$drawer.style.height = ''
        this.$element.classList.remove(`${name}--expanded`)
        this.$element.setAttribute('aria-expanded', 'false');
      }
    }
  }

  expandedDidChange (expanded) {
    this.$drawer.setAttribute('style', '')
  }
}
