
import browser from '../../../scripts/browser'

// Class names
const name = 'search'
const resultSelectedName = `${name}__result--selected`
const actionSelectedName = `${name}__action--selected`
const dropdownVisibleName = `${name}__dropdown--visible`

// Query requirements
const minQueryLength = 2

// Results limit
const maxResultCount = 7

// The search index is shared between multiple search instances
const searchIndexUrl = '/api/aerenzdall/v1/search/index-items'
let searchIndexItems = null

export default class Search {

  constructor ($element) {
    this.$element = $element

    // Result state
    this.dropdownVisible = false
    this.query = null
    this.selectedIndex = -1
    this.results = []
    this.$results = []

    // Search state
    this.queuedQuery = null
    this.searchBusy = false

    // Bind elements
    this.$dropdown = $element.querySelector(`.${name}__dropdown`)
    this.$resultWrapper = $element.querySelector(`.${name}__results`)
    this.$field = $element.querySelector(`.${name}__field`)
    this.$action = $element.querySelector(`.${name}__action`)

    // Bind field events
    if (browser.name !== 'ie') {
      this.$field.onblur = this.onFieldBlur.bind(this)
    } else {
      this.$field.onfocusout = this.onFieldBlur.bind(this)
    }

    this.$field.onchange = this.onFieldChange.bind(this)
    this.$field.onfocus = this.onFieldFocus.bind(this)
    this.$field.onkeydown = this.onFieldKeyDown.bind(this)
    this.$field.onkeyup = this.onFieldChange.bind(this)
  }

  onFieldFocus (evt) {
    // Make results visible, if any
    this.autocomplete(this.$field.value)
  }

  onFieldBlur (evt) {
    // Hide results if blur is not caused by a click inside the search form
    if (!this.$element.contains(evt.relatedTarget)) {
      this.setDropdownVisible(false)
    }
  }

  onFieldChange (evt) {
    this.autocomplete(this.$field.value)
  }

  onFieldKeyDown (evt) {
    switch (evt.keyCode) {
      // Up key pressed
      case 38:
        evt.preventDefault()
        // Select previous result
        this.setSelectedIndex(
          this.selectedIndex > -1
            ? this.selectedIndex - 1
            : this.results.length)
        break

      // Down key pressed
      case 40:
        evt.preventDefault()
        // Select next result
        this.setSelectedIndex(
          this.selectedIndex < this.results.length
            ? this.selectedIndex + 1
            : -1)
        break

      // Enter key pressed
      case 13:
        if (this.selectedIndex !== -1 &&
            this.selectedIndex < this.results.length) {
          evt.preventDefault()
          // Navigate to result page
          location.href = this.results[this.selectedIndex].url
        }
    }
  }

  onResultMouseOver (result, evt) {
    const index = this.results.indexOf(result)
    if (index !== -1) {
      this.setSelectedIndex(index)
    }
  }

  /**
   * Triggers autocompletion for given query.
   * @param {string} query Query string
   */
  async autocomplete (query) {
    // Show dropdown depending on query length
    this.setDropdownVisible(query.length >= minQueryLength)

    // Queue current query and wait until autocompletion finished
    if (this.searchBusy) {
      this.queuedQuery = query
      return
    }

    // Skip request if query hasn't changed
    if (this.query === query) {
      return
    }

    // Reset selection and make results visible
    this.setSelectedIndex(-1)

    // Set query and busy flag
    this.query = query
    this.searchBusy = true

    // Check if index has already been loaded
    if (searchIndexItems === null) {
      searchIndexItems = []
      try {
        // Request index using XMLHttpRequest (to support IE11)
        const response = await new Promise((resolve, reject) => {
          const xhr = new XMLHttpRequest()
          xhr.open('GET', searchIndexUrl)
          xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
              if (xhr.status >= 200 && xhr.status < 300) {
                resolve(xhr.response)
              } else {
                reject()
              }
            }
          }
          xhr.send(null)
        })

        // Parse response JSON
        searchIndexItems = JSON.parse(response)
      } catch (error) {
        // Index request failed, fall back to having no autocompletion results
      }
    }

    // Setup results array
    let results = []

    // Check for min query length
    if (query.length >= minQueryLength) {
      // Lowercase query and remove accents
      query = query
        .toLowerCase()
        .replace(/[áàâä]/g, 'a')
        .replace(/[úùûü]/g, 'u')
        .replace(/[éèêë]/g, 'e')
        .replace(/[ç]/g, 'c')

      // Split query into keywords
      const keywords = query.split(/[\s.,\/#!$%\^&\*;:{}=\-_`~()]/)

      // Filter items by each keyword
      results = searchIndexItems.filter(item => {
        let match = true
        let i = 0
        while (match && i < keywords.length) {
          match = item.keywords.indexOf(keywords[i]) !== -1
          i++
        }
        return match
      })

      // Limit results
      results = results.slice(0, maxResultCount)
    }

    // Render results
    this.results = results
    this.$results = this.renderResults(results)

    // Reset selection and make results visible
    this.setSelectedIndex(-1)

    // Clear busy flag
    this.searchBusy = false

    // Trigger previously queued autocompletion
    if (this.queuedQuery !== null) {
      this.autocomplete(this.queuedQuery)
      this.queuedQuery = null
    }
  }

  /**
   * Sets the selected index.
   * - `index = -1` refers to no selection
   * - `index in 0 ..< result-count` refers to a result at the same index
   * - `index = result-count` refers to the action
   * @param {number} selectedIndex Selected index
   */
  setSelectedIndex (selectedIndex) {
    if (this.selectedIndex !== selectedIndex) {
      const count = this.results.length

      // Remove selected state from previously selected result or action
      if (this.selectedIndex !== -1 && this.selectedIndex < count) {
        this.$results[this.selectedIndex].classList.remove(resultSelectedName)
      } else if (this.selectedIndex === count) {
        this.$action.classList.remove(actionSelectedName)
      }

      this.selectedIndex = selectedIndex;

      // Add selected state to result or action
      if (this.selectedIndex === count) {
        this.$action.classList.add(actionSelectedName)
      } else if (selectedIndex !== -1) {
        this.$results[selectedIndex].classList.add(resultSelectedName)
      }
    }
  }

  /**
   * Sets wether the dropdown is visible.
   * @param {boolean} dropdownVisible Wether dropdown is visible
   */
  setDropdownVisible (dropdownVisible) {
    if (this.dropdownVisible !== dropdownVisible) {
      this.dropdownVisible = dropdownVisible
      this.$dropdown.classList.toggle(dropdownVisibleName, dropdownVisible)
    }
  }

  /**
   * Renders given results and returns result elements.
   * @param {object[]} items Item objects
   * @return {HTMLElement[]} Item elements
   */
  renderResults (items) {
    this.$resultWrapper.innerHTML = ''

    let lastCategory = null
    return items.map(item => {
      if (item.category !== lastCategory) {
        const $category = document.createElement('span')
        $category.className = `${name}__category`
        $category.innerText = item.category
        this.$resultWrapper.appendChild($category)
        lastCategory = item.category
      }

      const $result = document.createElement('a')
      $result.className = `${name}__result`
      $result.href = item.url
      $result.innerText = item.title
      $result.onmouseover = this.onResultMouseOver.bind(this, item)

      if (browser.name === 'safari' || browser.name === 'ios') {
        // Make result focussable in iOS
        $result.setAttribute('tabindex', -1)
      }

      const $item = document.createElement('li')
      $item.appendChild($result)
      this.$resultWrapper.appendChild($item)
      return $item
    })
  }
}
