
const name = 'module-listing'

import TeaserGrid from '../../component/teaser-grid/teaser-grid.js'
import { getInstance } from '../../../scripts/view.js'

export default class ModuleListing {
  /**
   * Constructor
   * @param {HTMLElement} $element
   */
  constructor ($element) {
    this.loading = false
    this.loadingMore = false
    this.queuedQuery = null

    // Read element attrs
    this.$element = $element
    this.id = $element.dataset.id
    this.page = parseInt($element.dataset.page)
    this.baseUrl = $element.dataset.baseUrl

    const $grid = $element.querySelector('.teaser-grid')
    this.grid = getInstance($grid, TeaserGrid)

    // Bind elements
    this.$filters = Array.from($element.querySelectorAll(
      `.${name}__filters .field-select select`))

    this.$itemWrapper = $element.querySelector(`.teaser-grid__items`)
    this.$firstPage = $element.querySelector(`.${name}__page-first`)
    this.$nextPage = $element.querySelector(`.${name}__page-next`)

    // Listen to pagination link clicks
    if (this.$firstPage) {
      this.$firstPage.onclick = this.firstPageDidClick.bind(this)
    }
    this.$nextPage.onclick = this.nextPageDidClick.bind(this)

    // Listen to filter changes
    this.$filters.forEach($filter =>
      $filter.addEventListener('change', this.filtersDidChange.bind(this)))
  }

  /**
   * Triggered when the first page link is clicked.
   * @param {MouseEvent} evt Event
   * @return {void}
   */
  firstPageDidClick (evt) {
    evt.preventDefault()
    this.loadItems(1, this.getFilters())
  }

  /**
   * Triggered when next page link is clicked.
   * @param {MouseEvent} evt Event
   * @return {void}
   */
  nextPageDidClick (evt) {
    evt.preventDefault()
    this.loadItems(++this.page, this.getFilters())
  }

  /**
   * Triggered when filters change.
   * @param {Event} evt Event
   * @return {void}
   */
  filtersDidChange (evt) {
    this.loadItems(1, this.getFilters())
  }

  /**
   * Loads the first page with the given base URL.
   * Gets called e.g. by the calendar component when changing the slide.
   * @param {string} baseUrl Page base URL
   */
  async loadPage (baseUrl) {
    if (this.baseUrl !== baseUrl) {
      this.page = 1
      this.baseUrl = baseUrl
      await this.loadItems(this.page, [])
    }
  }

  /**
   * Loads the items for the given page URL and filters.
   * @param {number} page Page
   * @param {array} filters Filters
   */
  async loadItems (page = 1, filters = []) {
    // Queue query when already loading
    if (this.loading) {
      this.queuedQuery = { page, filters }
      return
    }

    // Set loading flag
    this.setLoading(true, page > 1)

    // Compose url
    let pageUrl = this.baseUrl
    if (filters.join('') !== '') {
      pageUrl += `/filter/${filters.join('+')}`
    }
    if (page > 1) {
      pageUrl += `/page/${page}`
    }

    // Try to load next page via AJAX
    let response = null
    try {
      // Request index using XMLHttpRequest (to support IE11)
      response = await new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest()
        xhr.open('GET', pageUrl)
        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4) {
            if (xhr.status >= 200 && xhr.status < 300) {
              resolve(xhr.response)
            } else {
              reject()
            }
          }
        }
        xhr.send(null)
      })

      // Try to parse document
      const parser = new DOMParser()
      const $document = parser.parseFromString(response, 'text/html')

      // Identify the same listing module inside the document
      // using the 'id' data attribute
      const $element = $document.querySelector(`.${name}[data-id="${this.id}"]`)
      const maxPage = parseInt($element.dataset.maxPage)
      const $items = Array.from($element.querySelectorAll(
        '.teaser-grid__item'))

      // Remove first page link if showing content from the beginning
      if (page === 1 && this.$firstPage !== null) {
        this.$firstPage.parentNode.removeChild(this.$firstPage)
        this.$firstPage = null
      }

      // Update next button
      if (page >= maxPage) {
        this.$nextPage.classList.add(`${name}__page-next--disabled`)
      } else {
        this.$nextPage.classList.remove(`${name}__page-next--disabled`)
      }

      // Clear items if the first page is being requested
      if (page === 1) {
        this.$itemWrapper.innerHTML = ''
      }

      // Append items and update page
      $items.forEach($item => this.$itemWrapper.appendChild($item))
      this.grid.update()

      // Update state
      this.page = page
      this.maxPage = maxPage

      // Move focus to the first teaser loaded, if any
      $items.at(0)?.querySelector('.teaser')?.focus()
      history.replaceState({ page, filters }, '', pageUrl)

      // Apply filters (if triggered by popstate event)
      this.$filters.map(($filter, index) => {
        $filter.value = filters[index] ?? ''
      })
    } catch (error) {
      // Loading next page failed due to networking or not supported browser
      // Fallback to basic page navigation
      window.location.href = pageUrl
    }

    // Clear loading flag
    this.setLoading(false)

    // Trigger queued query
    if (this.queuedQuery !== null) {
      const { page, filters } = this.queuedQuery
      this.queuedQuery = null
      this.loadItems(page, filters)
    }
  }

  setLoading (loading, loadingMore = false) {
    if (this.loading !== loading) {
      this.loading = loading
      if (loading) {
        this.loadingMore = loadingMore
        this.$element.classList.add(
          this.loadingMore ? `${name}--loading-more` : `${name}--loading`)
      } else {
        this.$element.classList.remove(
          this.loadingMore ? `${name}--loading-more` : `${name}--loading`)
      }
    }
  }

  getFilters () {
    return this.$filters.map(($filter, index) =>
      $filter && $filter.value ? $filter.value : null)
  }
}
