
import { register } from './view'

import ArticleAccordion from '../views/article/accordion/accordion'
import ComponentCalendar from '../views/component/calendar/calendar'
import ComponentSearch from '../views/component/search/search'
import ComponentTeaserGrid from '../views/component/teaser-grid/teaser-grid'
import ModuleListing from '../views/module/listing/listing'

import 'lazysizes'
import './browser'

// Register components
register('calendar', ComponentCalendar)
register('search', ComponentSearch)
register('teaser-grid', ComponentTeaserGrid)

// Register modules
register('module-listing', ModuleListing)

// Register article parts
register('article-accordion', ArticleAccordion)

// Load polyfill for object-fit if this feature is not supported
if ('objectFit' in document.documentElement.style === false) {
  const url = 'https://cdnjs.cloudflare.com/ajax/libs/object-fit-images/3.2.4/ofi.min.js'
  const $script = document.createElement('script')
  $script.onload = () => { window.objectFitImages() }
  $script.async = true
  $script.src = url
  document.documentElement.appendChild($script)
}
